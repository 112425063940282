import DataFilterHelper from './DataFilterHelper';
import { FILTER_TYPE_SORT } from '@/helpers/data-filter/DataFilterTypes';

class DestinationDataFilterHelper extends DataFilterHelper {
  defaultValues = {
    [FILTER_TYPE_SORT]: null,
  };

  /**
   * @returns {FilterGroup[]}
   */
  prepareFilterGroups() {
    /**
     * @type {FilterGroup[]}
     */
    const groups = [
      this.addRadioGroupItems({
        itemsType: FILTER_TYPE_SORT,
        items: [
          {
            title: 'Sort by popular',
            value: { column: 'views_count' },
          },
        ],
      }),
    ];

    return groups.filter((v) => v);
  }

  prepareFilterValuesToQueryVariables(groups = {}) {
    const transformed = super.prepareFilterValuesToQueryVariables(groups);
    const sort = transformed[FILTER_TYPE_SORT];
    if (sort?.column === 'views_count') {
      sort.order = 'desc';
    }

    return {
      sort,
    };
  }
}

export default DestinationDataFilterHelper.create();
