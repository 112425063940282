<template>
  <div class="page-wrapper container">
    <h1 class="page-title" data-aos="fade-up">Destinations</h1>
    <div class="page-desc" data-aos="fade-up">
      <span>{{ description }}</span>
    </div>

    <div class="app-data-filter-wrapper">
      <AppDataFilterOpenButton @click="handleOpenFilter" />
    </div>

    <div class="content-wrp">
      <div class="row-considering-mobile">
        <EntityCard
          v-for="destination in destinations"
          :key="destination.id"
          class="col-12 col-sm-6 col-lg-4"
          :data="destination"
          data-aos="fade-up"
          type="destinations"
        />
      </div>
      <infinite-loading :identifier="infiniteLoaderId" @infinite="loadMore" />
    </div>

    <AppDataFilterMain
      v-model="filterValues"
      :filter-groups="filterGroups"
      :init-open="isAppDataFilterOpened"
      :is-mobile="isMobileScreen"
      @input="handleChangeFilterValues"
      @open="handleOpenFilter"
      @close="handleCloseFilter"
    >
      <template #bottom-content>
        <div v-if="!isMuseumMemberRole" class="make-request make-request--inside-data-filter">
          <div class="make-request__inner">
            <p class="make-request__title">Can't find your destination?</p>
            <p class="make-request__text">
              Let us know where you are going and we'll create a bespoke art guide for you
            </p>
            <button class="border-btn btn make-request__btn" type="button" @click="handleOpenLiaisonModal">
              Request bespoke art guide
            </button>
          </div>
        </div>
      </template>
    </AppDataFilterMain>

    <MemberLiaisonModal :is-open="isOpenLiaisonModal" :liaison="liaison" @close="handleCloseLiaisonModal" />
  </div>
</template>

<script>
import { areRouteQueriesDifferent } from '@/helpers/routerHelper';
import { EntityTypes } from '@/helpers/entityType';
import DestinationDataFilterHelper from '@/helpers/data-filter/DestinationDataFilterHelper';

import pageMetadataQuery from '@/graphql/PageMetadata.query.gql';
import destinationsQuery from '@/graphql/destination/Destinations.query.gql';
import meLiaisonQuery from '@/graphql/me/MeLiaison.query.gql';

import AppDataFilterMain from '@/components/data-filter/AppDataFilterMain';
import AppDataFilterOpenButton from '@/components/data-filter/AppDataFilterOpenButton';
import EntityCard from '@/components/partials/EntityCard';

const DATA_PER_PAGE = 9;

export default {
  name: 'DestinationsPage',
  components: {
    AppDataFilterMain,
    AppDataFilterOpenButton,
    EntityCard,
    MemberLiaisonModal: () =>
      import(/* webpackChunkName: "MemberLiaisonModal" */ '@/components/partials/elements/MemberLiaisonModal'),
  },
  metaInfo: {
    title: 'Destinations',
  },
  data() {
    return {
      description: '',
      destinations: [],
      loadingDestinations: true,
      getMoreDestinations: false,
      page: 1,
      infiniteLoaderId: +new Date(),
      filterGroups: [],
      filterValues: {},
      changingRouteAfterFilterUpdated: false,
      liaison: null,
      isOpenLiaisonModal: false,
    };
  },
  computed: {
    filterQueryVariables() {
      return DestinationDataFilterHelper.prepareFilterValuesToQueryVariables(this.filterValues);
    },
    isAppDataFilterOpened() {
      return !!this.$store.state.isAppDataFilterOpened;
    },
    isMobileScreen() {
      return !!this.$store.state.isMobileScreen;
    },
    isMuseumMemberRole() {
      return !!this.$store.state.museumRole;
    },
  },
  created() {
    this.filterValues = DestinationDataFilterHelper.getFilterValuesFromRouteQuery(this.$route.query);
    this.filterGroups = DestinationDataFilterHelper.prepareFilterGroups();
  },
  beforeRouteUpdate(to, from, next) {
    if (!this.changingRouteAfterFilterUpdated) {
      this.filterValues = DestinationDataFilterHelper.getFilterValuesFromRouteQuery(to.query);
    }
    this.resetFetchDataOptions();
    this.changingRouteAfterFilterUpdated = false;
    next();
  },
  apollo: {
    pageMetadata: {
      query: pageMetadataQuery,
      variables() {
        return {
          entityType: EntityTypes.destination,
        };
      },
      result(result) {
        this.description = result.data.pageMetadata?.description;
      },
    },
    me: {
      query: meLiaisonQuery,
      result(result) {
        if (result.data?.me.liaison) {
          this.liaison = result.data.me.liaison;
        }
      },
    },
  },
  methods: {
    handleOpenLiaisonModal() {
      this.isOpenLiaisonModal = true;
      this.handleCloseFilter();

      this.$nextTick().then(() => {
        if (this.isMobileScreen) {
          this.$store.dispatch('disableScroll');
        }
      });
    },
    handleCloseLiaisonModal() {
      this.isOpenLiaisonModal = false;
      if (this.isMobileScreen) {
        this.$store.dispatch('enableScroll');
      }
    },
    handleOpenFilter() {
      this.$store.dispatch('toggleAppDataFilter', true);
    },
    handleCloseFilter() {
      this.$store.dispatch('toggleAppDataFilter', false);
    },
    handleChangeFilterValues() {
      const query = DestinationDataFilterHelper.setFilterValuesToRouteQuery(this.filterValues);

      if (areRouteQueriesDifferent(this.$route.query, query)) {
        this.changingRouteAfterFilterUpdated = true;
        this.$router.push({ name: this.$route.name, params: this.$route.params, query });
      }
    },
    loadMore(loadingState) {
      this.loadingDestinations = true;
      this.$apollo
        .query({
          query: destinationsQuery,
          fetchPolicy: 'no-cache',
          variables: {
            limit: DATA_PER_PAGE,
            needCommonData: true,
            page: this.page,
            ...this.filterQueryVariables,
          },
        })
        .then(({ data }) => {
          const { list: destinationsPage } = data;

          this.destinations.push(...destinationsPage.data);
          this.getMoreDestinations = destinationsPage.hasMorePages;
          this.loadingDestinations = false;

          if (!this.getMoreDestinations) {
            loadingState.complete();
            return;
          }
          this.page++;
          loadingState.loaded();
        })
        .catch(() => {
          this.loadingDestinations = false;
          loadingState.complete();
        });
    },
    resetFetchDataOptions() {
      this.loadingDestinations = true;
      this.page = 1;
      this.destinations = [];
      this.infiniteLoaderId += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  margin-top: 100px;
  padding-bottom: 60px;
}
.page-desc {
  max-width: 550px;
}
</style>
